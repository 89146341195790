body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ui.right.aligned.search.item {
  align-self: center;
}

.header > a {
  color: black;
}

.header > a:visited {
  color: grey;
}

.main-content-pushable {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content-pusher {
  flex: 1 0 auto;
}

footer {
  border-width: 0px 0px 0px 0px !important;
  border-image-width: 0px 0px 0px 0px !important;
  bottom: 0;
  margin: 0px;
  flex-shrink: 0;
}

footer a {
  color: white;
}

.ui.cards > .card.width-fit,
.ui.card.width-fit {
  width: auto;
}

.ui.container.desktop-framed {
  background-color: white;
}

@media only screen and (min-width: 768px) {
  .ui.container.desktop-framed {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 2px;
    border-right-width: 2px;
    border-color: lightgray;
  }
}
